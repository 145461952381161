import React, { useState, useCallback } from 'react';
import { useDixa } from '@devsbb/cs-sdk';

import PropTypes from 'prop-types';
import { PureTranslator } from 'ggApp/modules/translator';

import withWebView from 'ggApp/shared/hocs/withWebView';
import {
    registerAcceptAllCookies,
    syncGoogleConsentState,
} from 'ggApp/modules/cookiePolicy/tracking';
import { withCookieConsentActions } from 'ggApp/modules/cookiePolicy/enhancers';
import asyncComponent from 'ggApp/shared/components/async';
import loadConfiguration from 'ggApp/utils/configuration';

import {
    Badge,
    Badges,
    Body,
    ContactUs,
    Divider,
    IconLink,
    Legal,
    LinkList,
    PressAndCopyright,
    Root,
    AppStoreProviders,
    ScoreAndPayMethods,
    SiteMap,
    Social,
    Copyright,
    Link,
    LangStore,
    Language,
    Store,
    ContactText,
    ContactButton,
    ReviewIO,
    ModalLink,
} from './styles';

import GooglePlayImage from './assets/google-play.svg';
import AppStoreImage from './assets/app-store.svg';

const StoreWithChecker = withWebView(Store);

const { INTERNAL_TOOLS_ENABLED } = loadConfiguration();

const InternalTools = asyncComponent(() =>
    import('ggApp/shared/components/BaseLayout/Footer/InternalTools'),
);
const CookiePolicyModal = asyncComponent(() =>
    import('ggApp/modules/cookiePolicy/components/modal'),
);

const Footer = ({
    footerLinks: {
        contact = {},
        social = [],
        company,
        offerings,
        tips,
        badges,
        copyrightText,
        legal,
    },
    consentCookiePolicy,
    storeActive,
}) => {
    const isUsStore = storeActive?.code === 'us' || storeActive?.code === 'business_us';
    const [modal, setModal] = useState(null);
    const { show: showDixaMessenger } = useDixa();

    const createModalRef = useCallback((newModal) => {
        setModal(newModal);
    }, []);

    const handleClick = useCallback(() => {
        if (modal) {
            modal.openModal();
        }
    }, [modal]);

    const handleModalClose = useCallback(() => {
        if (modal) {
            modal.closeModal();
        }
    }, [modal]);

    const handleAcceptAll = useCallback(() => {
        consentCookiePolicy();
        registerAcceptAllCookies();
        const cookieConsentState = {
            preferenceCookie: true,
            statisticsCookie: true,
            marketingCookie: true,
            explicitConsent: true,
        };
        syncGoogleConsentState(cookieConsentState);
        window.location.reload();
    }, [consentCookiePolicy]);

    return (
        <Root isApp={window.ReactNativeWebView}>
            <Divider />
            <ContactUs>
                <ContactText>{contact.text}</ContactText>
                <ContactButton outlined onClick={showDixaMessenger}>
                    {contact.buttonText}
                </ContactButton>
            </ContactUs>

            <Divider />

            <Body>
                <LangStore>
                    <Language />
                    <StoreWithChecker />
                </LangStore>

                <SiteMap>
                    <LinkList title={company.title} list={company.links} />
                    {!storeActive?.code?.startsWith('business_') ? (
                        <LinkList title={offerings.title} list={offerings.links} />
                    ) : null}
                    {!storeActive?.code?.startsWith('business_') ? (
                        <LinkList title={tips.title} list={tips.links} />
                    ) : null}
                </SiteMap>
            </Body>

            <Divider />

            <ScoreAndPayMethods>
                {badges ? (
                    <Badges>
                        {badges.map((badge, i) => {
                            const { key, style, alt } = badge;
                            return (
                                <Badge key={key || `${badge}-${i}`} style={style} alt={alt || ''}>
                                    {badge.children}
                                </Badge>
                            );
                        })}
                    </Badges>
                ) : null}

                <ReviewIO withLink={storeActive?.code !== 'us'} />

                {storeActive?.code !== 'us' && !storeActive?.code?.startsWith('business_') && (
                    <AppStoreProviders>
                        <a
                            rel="noopener noreferrer"
                            href="https://apps.apple.com/de/app/grover-rent-tech-flexibly/id1495992170"
                            target="_blank"
                        >
                            <img src={AppStoreImage} alt="Apple Store" />
                        </a>

                        <a
                            rel="noopener noreferrer"
                            href="https://play.google.com/store/apps/details?id=com.groverapp"
                            target="_blank"
                        >
                            <img src={GooglePlayImage} alt="Google Play Store" />
                        </a>
                    </AppStoreProviders>
                )}
            </ScoreAndPayMethods>

            <Divider />

            <PressAndCopyright>
                <Social>
                    {social.map((item) => (
                        <IconLink key={item.link} item={item} />
                    ))}
                </Social>

                <Legal>
                    {!isUsStore && (
                        <ModalLink onClick={handleClick}>
                            <PureTranslator tk="LEGAL_LINKS_DATA_PREFERENCES" />
                        </ModalLink>
                    )}

                    {legal.links.map((item) => (
                        <Link key={item.link} external={!item.internal} to={item.link}>
                            {item.text}
                        </Link>
                    ))}
                </Legal>

                <Copyright>{copyrightText}</Copyright>
            </PressAndCopyright>

            {INTERNAL_TOOLS_ENABLED ? (
                <>
                    <Divider />
                    <InternalTools />
                </>
            ) : null}

            <CookiePolicyModal
                modalRef={createModalRef}
                acceptAll={handleAcceptAll}
                handleModalClose={handleModalClose}
                onCustomSave={() => window.sessionStorage.setItem('tempHideCookiesConsent', true)}
            />
        </Root>
    );
};

Footer.propTypes = {
    storeActive: PropTypes.shape({
        activeLanguage: PropTypes.string,
        checkout_text: PropTypes.string,
        code: PropTypes.string,
        country_code: PropTypes.string,
        country_id: PropTypes.number,
        current: PropTypes.bool,
        default_currency: PropTypes.string,
        default_language: PropTypes.string,
        enabled_on_footer: PropTypes.bool,
        languages: PropTypes.array,
        logo_url: PropTypes.string,
        name: PropTypes.string,
        new_checkout_enabled: PropTypes.bool,
        offline: PropTypes.bool,
        shipping_cost: PropTypes.number,
        store_id: PropTypes.number,
        store_type: PropTypes.string,
        url: PropTypes.string,
    }),
    footerLinks: PropTypes.shape({
        social: PropTypes.array,
        contact: PropTypes.object,
        faq: PropTypes.object,
        company: PropTypes.object,
        offerings: PropTypes.object,
        tips: PropTypes.object,
        badges: PropTypes.array,
        score: PropTypes.object,
        copyrightText: PropTypes.node,
        legal: PropTypes.object,
    }),
};

Footer.defaultProps = {
    storeActive: {},
    footerLinks: {},
};

export default withCookieConsentActions(Footer);
